@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
    --bg: rgb(29, 29, 29);
    --bg-lighter: rgb(39, 39, 39);
    --bg-lightest: rgb(58, 58, 58);
    --highlight: rgb(88, 88, 88);
    --bg-transparent: rgba(48, 48, 48, 0.75);
    --border: rgb(75, 75, 75);
    --accent: #4687ff;

    --text: white;
    --alt-text: #CBCBCB;

    --container-width: 850px;
    --padding: 0 20px;
}
* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    padding: 0;
    margin: 0;
    border: none;
}
/* Scrollbar */
::-webkit-scrollbar {
    /* background: var(--content-bg); */
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /* background-color: rgb(73, 73, 73); */
    background-color: gray;
}
::-webkit-scrollbar-thumb:active {
    /* background: rgb(54, 54, 54); */
    background-color: rgb(73, 73, 73);
}
body {
    background-color: var(--bg);
    color: var(--text);
    scrollbar-gutter: stable;
}

body:has(.backdrop) {
    overflow: hidden;
}

button, .button, *[role="button"], summary {
    cursor: pointer;
}
button,
.button,
select {
    background-color: var(--bg-transparent);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

    min-width: 185px;
    padding: 6px 30px;
    border: 1px solid var(--border);
    border-radius: 5px;

    font-size: 12pt;
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    position: relative;
    overflow: hidden;

    /* margin-bottom: 6px; */
}
button.small {
    padding: 6px 16px;
    min-width: unset;
}
button.active {
    background-color: var(--accent);
}
button svg {
    --fill: white;
}
select {
    text-align: left;
    min-width: 150px;
    padding: 6px 15px;
    margin-right: 10px;
}
button[disabled] {
    color: var(--alt-text);
}
button > span {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 0;

    background-color: white;
    border-radius: 30px 30px 0 0;

    transform: translateY(100%) scale(0.8);
    transition: transform 0.1s ease, border-radius 0.15s ease;
}
button:hover,
button:focus-visible {
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 20px rgba(70, 135, 255, 0.35);
}
button:hover > p {
    color: black;
    position: relative;
    z-index: 1;
}
button:hover > span {
    transform: unset;
    border-radius: 0;
}
button:hover .icon path { --fill: black; }
button.button_blue {
    background-color: var(--accent);
    border-color: var(--accent);
}
/* button.button_green {
    background-color: rgb(80, 124, 59);
    border-color: rgb(80, 124, 59);
} */

button.active:hover .icon path { --fill: var(--accent); }

button.subtle_button {
    box-shadow: none;
    background-color: unset;
    padding: 0 12px 6px;

    text-align: left;
}


.fullwidth { width: 100%; max-width: unset; }



/* Title */
*[data-title] { position: relative; }
*[data-title]::after {
    content: attr(data-title);
    position: absolute;
    z-index: 1;
    top: calc(100% + 6px);
    left: 50%;
    width: max-content;

    background-color: var(--bg);
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px 16px;

    opacity: 0;
    pointer-events: none;
    transform-origin: top;
    transform: translateX(-50%) translateY(-4px) scale(0.9);
    transition: transform 0.1s ease, opacity 0.1s ease;
}
*[data-title].title_above::after {
    top: unset; bottom: calc(100% + 6px);
    transform: translateX(-50%) translateY(4px) scale(0.9);
    transform-origin: bottom;
}
*[data-title]:hover::after {
    opacity: 1;
    transform: translateX(-50%);
}


.container {
    width: 100%;
    max-width: var(--container-width);
    margin: 0 auto;
    padding: var(--padding);
    /* outline: 1px solid white; */
}
.col {
    width: 100%;
}
.bordered { border: 1px solid var(--border); }
hr.vr {
    height: 150px;
    margin: auto;
    width: 1px;
    background-color: var(--border);
    margin-right: 24px;
}
.alt_text { color: var(--alt-text); }
strong, .emphasize { color:var(--text); }
.gray { color: gray; }
.small { font-size: 75%; }
.weight_500 { font-weight: 500; }
.flex { display: flex; }
.center_vertically { align-items: center; }
.margin_left_auto { margin-left: auto; }
.center { text-align: center; }
.fcenter { display: flex; justify-content: center; align-items: center; }
.hover_underline:hover { text-decoration: underline; }

h1, h2, h3, h4, h5, h6 {
    font-weight: 900;
}
h1 {
    font-size: 36pt;
    text-shadow: 2px 1px 8px rgba(0, 0, 0, 0.8);
}
h2 { font-size: 26pt; }
h3 { font-size: 22pt; }
h4 { font-size: 18pt; margin-bottom: 6px; }
h5 { font-size: 16pt; margin: 4px 0; }
h6 { font-size: 12pt; }


table {
    width: 100%;
}
table tr.highlight {
    /* background-color: var(--accent); */
    animation: row_highlight 4s ease-out;
}
@keyframes row_highlight {
    0%  { background-color: var(--accent); }
    60% { background-color: var(--accent); }
}
table tr.highlight .rank span { color: white; }
table th {
    text-align: left;
}
table th, table td {
    padding: 3px 5px;
}
table tr:nth-child(odd) {
    background-color: var(--bg-lightest);
}


/* Misc */
.icon {
    --fill: var(--alt-text);
    vertical-align: -16%;
    width: 22px; height: 22px;
    display: inline;
}
.icon svg {
    width: 22px; height: 22px;
    margin-bottom: -2px;
}
.icon.small {
    vertical-align: 0%;
}
.icon.small svg {
    width: 16px; height: 16px;
}
.icon:not(:last-child) { margin-right: 8px; }
.icon path { fill: var(--fill); }
strong .icon path,
.emphasize .icon path { --fill: var(--text); }

#bg_image {
    position: absolute;
    inset: 0;
    z-index: -1;

    /* --image: url('/FinnaSMP/2021-07-12_19.18.25.png'); */
    background-image:
        linear-gradient(35deg, var(--bg) 30%, transparent 60%),
        linear-gradient(0deg, var(--bg) 25%, transparent 100%),
        var(--image);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    transform: translateY(0px);
    will-change: transform;

    transition: filter 1s ease, scale 1s ease;
}
body:has(#world) #bg_image {
    /* scale: 1.02; */
    filter: blur(16px) brightness(70%);
}


/* Slider tabs */
.tabs {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    padding: 6px;

    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);

    position: relative;
    user-select: none;
}
.tabs > .tab {
    font-size: 14pt;
    opacity: 0.5;
    padding: 5px 24px;
    border-radius: 100px;
    text-align: center;
}
.tabs > .icon {
    margin-top: 4px;
    margin-left: 9px;
}
.tabs > .tab:hover,
.tabs > .tab:focus-visible {
    text-decoration: underline;
}
.tabs > .tab.active {
    font-weight: bolder;
    opacity: 1;
    color: black;
    background-color: white;
}

/* Nav */
.main_header {
    margin-top: 60px;
}
.main_nav {
    margin: 20px auto;
}
.main_nav > .inner {
    align-items: center;
}
.main_nav .buttons {
    margin-left: auto;
}
.main_nav .tabs {
    margin-top: 12px;
}

/* List */
.worlds_list {
    display: block;
}
.worlds_list a {
    text-decoration: none;
}

h2.separator {
    font-size: 1.6em;
    margin-bottom: 10px;
    margin-top: 35px;
    border-bottom: 2px solid var(--border);
}
h2.separator:has(+ *:not(world-item)) {
    display: none;
}

#filters {
    /* height: 0; */
    max-height: 0;
    background-color: var(--bg-transparent);
    overflow: hidden;
    padding: 0 24px;
    margin-top: 12px;
    border-radius: 12px;
    /* border: 1px solid var(--border); */
    box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.35);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);

    transition: 0.3s max-height cubic-bezier(0.23, 1, 0.320, 1), padding 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}
#filters.open {
    max-height: 300px;
    padding: 12px 24px;
}

world-item {
    display: flex;
    position: relative;

    background: linear-gradient(90deg, var(--bg-lighter) 20%, transparent 100%), var(--image);
    padding: 20px 30px !important;
    margin-bottom: 15px !important;
    
    background-color: var(--bg-lighter) !important;
    background-size: cover !important;
    background-position: center !important;

    border-radius: 5px;
    border: 1px solid var(--border);
    transition: transform 0.08s ease, box-shadow 0.1s ease;
    animation: list_anim 1.2s cubic-bezier(0.19, 1, 0.22, 1) backwards;
    animation-delay: var(--anim-delay);
}
@keyframes list_anim {
    from {
        opacity: 0;
        transform: translateY(calc(12px + var(--anim-distance)));
    }
}
world-item:hover,
world-item:focus-visible,
world-item:has(> a:focus-visible) {
    z-index: 1;
    transform: scale(1.02);
    box-shadow: 2px 2px 12px rgba(64, 64, 64, 0.6);
}
world-item > a {
    position: absolute;
    inset: 0;
}
world-item .col.right {
    width: unset;
    margin-left: auto;
}
world-item h3 {
    padding-bottom: 3px;
    color: var(--text);
}
world-item .icons {
    background-color: var(--bg-transparent);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

    padding: 5px 12px;
    border-radius: 0 0 10px 10px;
}
world-item button {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid var(--border);
}
/* world-item .icons .icon path { fill: var(--text);} */
/* world-item .icons .icon:hover { transform: scale(1.1); } */
world-item .icons .icon:hover path { fill: var(--text); }




/* Footer */
#footer {
    border-top: 1px solid var(--border);
    padding: 25px 0;
    margin-top: 50px;
}





/* World page */
#world {
    --container-width: 1050px;
    position: relative;
}
#world h1 {
    font-size: 40pt;
}
#world h3 { padding-bottom: 6px; }
#world header {
    background-color: var(--bg);

    min-height: 200px;
    max-height: 450px;
    padding: 0 !important;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid var(--border);

    position: relative;
    overflow: hidden;
}
#world header button.close {
    position: absolute;
    left: 32px;
    top: 20px;
    min-width: unset;
    padding: 12px;
    font-size: 12pt;
    font-weight: 600;
}
#world header img {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    margin: 0;
}
/* #world header img:hover {
    transform: scale(1.02);
} */
#world .banner {
    z-index: 1;
    position: relative;
    margin-top: -52px;
    padding: 20px 32px;
    align-items: center;
    width: 100%;
    /* justify-content: space-between; */
}
#world .banner > .buttons {
    display: flex;
    margin-left: auto !important;
    /* outline: 1px solid red; */
}
#world .banner button {
    padding: 10px 30px;
}

#world a {
    text-decoration: none;
}

.map_preview {
    width: 100%;

    --bg: rgba(31, 31, 31, 0.5);
    background-image:
        linear-gradient(0deg, var(--bg) 0%, var(--bg) 100%),
        var(--image);
    background-size: cover;
    object-position: center;

    transition: 0.05s border-color ease;
}
.map_preview:hover,
.map_preview:focus-visible {
    --bg: rgba(59, 59, 59, 0.5);
    border-color: var(--alt-text);
}
.map_preview * {
    color: var(--text);
}
.map_preview > span {
    font-size: 13pt;
    font-weight: bold;
    max-width: max-content;
    margin-left: auto;
}

/* Dropdown */
.dropdown_container {
    position: relative;
}
button.dropdown {
    width: 45px;
    min-width: unset;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.dropdown_content {
    position: absolute;
    top: calc(100% + 6px);
    right: 0;

    display: flex;
    flex-direction: column;
    /* row-gap: 1px; */

    width: 240px;
    /* background-color: var(--border); */
    border-radius: 5px;
    border: 1px solid var(--border);
    overflow: hidden;

    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.15s ease, transform 0.15s ease;
}
.dropdown_content:focus,
.dropdown_content:focus-within,
button.dropdown:focus-within ~ .dropdown_content {
    opacity: 1;
    pointer-events: all;
}
.dropdown_content button {
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
}

/* #world .banner > *:first-child:not(:last-child) > button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: 2px solid red;
} */
#world .banner > *:last-child:not(:first-child) > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#world .info {
    margin-bottom: 4px;
    width: 100%;
}

.tags {
    /* margin-bottom: 24px; */
    display: flex;
    flex-wrap: wrap;
}
.tag {
    --color: white;
    --bg: color-mix(in lch, var(--color) 35%, black);

    color: var(--color);
    background-color: var(--bg);
        /* background-color: rgba(83, 113, 148, 0.4); */

    padding: 5px 15px;
    margin: 0 6px 6px 0;
    border-radius: 100px;
    font-size: 11pt;

    cursor: pointer;
    user-select: none;
    transition: background-color 0.08s ease;
}
.tag > .after {
    float: right;
    margin-left: 9px;

    /* border-radius: 100px; */
    /* color: var(--bg); */
    /* background-color: var(--color); */
    transition: transform 0.3s ease;
}
/* .tag > .after .icon path { --fill: var(--bg); } */
.tag.active > .after { transform: rotate(45deg); }

.tag.active,
.tag:hover:not(.toggle_tag),
.tag:focus-visible:not(.toggle_tag) {
    color: var(--bg);
    background-color: var(--color);
}
.tag .icon path { --fill: var(--color); }
.tag.active .icon path,
.tag:hover:not(.toggle_tag) .icon path,
.tag:focus-visible:not(.toggle_tag) .icon path { --fill: var(--bg); }

.tag.toggle_tag:hover:not(.active),
.tag.toggle_tag:focus-visible:not(.active) {
    /* background-color:  */
    /* color: color-mix(in lch, var(--color) 50%, black); */
}

#world .item_row {
    column-gap: 12px;
}

#world .item {
    position: relative;

    background-color: var(--bg-transparent);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    width: 100%;
    padding: 20px 32px;
    border-radius: 10px;
    margin-bottom: 24px;
}
#world .item p:has(.icon)::first-line {
    line-height: 100%;
}
#world .item p {
    margin-top: 5px;
    line-height: 140%;
    font-size: 11pt;
}
#world .item.nopad {
    padding: 0;
    overflow: hidden;
}
iframe {
    width: 100%;
    min-height: 300px;
}

#world .item {
    column-gap: 16px;
}

/* .players {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 3px;
}
.players .player {
    display: flex;
    align-items: center;
} */
.players .player:hover,
.players .player:focus-visible {
    text-decoration: underline;
    color: var(--text);
    cursor: pointer;
}

.player_stats .close {
    margin-bottom: 15px;
    width: 100%;
    position: sticky;
    top: 0px;
}


/* Gallery */
#images {
    background-color: unset !important;
}
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(305px , 1fr));
    grid-gap: 6px;
}
.gallery img {
    background-color: var(--bg);
    width: 100%;
    border-radius: 5px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    transition: transform 0.15s ease, box-shadow 0.2s ease;
    border: 2px solid transparent;
}
.gallery img:hover,
.gallery img:focus-visible {
    z-index: 1;
    filter: brightness(110%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
    transform: scale(1.07) translateY(-1px) perspective(650px) rotateX(6deg);
    border-color: lightgray;
}

.backdrop {
    position: fixed;
    z-index: 1;
    inset: 0;

    background: rgba(15, 15, 15, 0.8);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);

    animation: backdrop-in 0.25s ease;
}
@keyframes backdrop-in {
    from { opacity: 0; }
}
.enlarged {
    position: fixed;
    z-index: 3;
    inset: 0;
}
.enlarged > img {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: contain;

    background-image: var(--thumb);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

img-info {
    position: absolute;
    inset: 12px;
    top: unset;
    z-index: 1;

    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 80px;
    display: flex;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid transparent;

    background-color: transparent;
    transform: translateY(calc(100% - 24px));
    transition:
        transform 0.35s ease,
        background-color 0.2s ease,
        box-shadow 0.2s ease,
        border-color 0.2s ease;
    transition-delay: 1.5s;
}
img-info:hover,
img-info:focus-visible {
    background-color: var(--bg);
    border-color: var(--bg-lightest);
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.9);
    transform: translateY(0);

    transition-delay: 0s;
}
img-info::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    background-color: var(--alt-text);
    width: 85px;
    height: 6px;
    border-radius: 100px;

    transition: opacity 0.3s ease, width 0.2s ease;
}
img-info:hover::before,
img-info:focus-visible::before {
    opacity: 0.6;
    width: 100px;
}
img-info p {
    padding-bottom: 4px;
}
/* img-info > div {
    width: 100%;
} */
img-info .right {
    margin-left: auto;
    padding-left: 20px;
    text-align: right;
}
/* img-info nav {
    display: flex;
    justify-content: center;
}
img-info nav img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    margin: 0 1px;
} */
/* .enlarged {
    animation: image_viewer 0.2s ease;
}
@keyframes image_viewer {
    from {
        transform: translateY(200px);
        opacity: 0;
    }
} */


img.img_anim {
    border-radius: 5px;
    border: 2px solid transparent;

    /* transition: transform 0.25s ease, width 0.27s ease; */
    transition: 0.35s cubic-bezier(0.19, 0.8, 0.22, 1);
    object-fit: contain;

    position: fixed;
    top:0; left:0;
    z-index: 2;
}



/* Stats */
.player_stats {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    overflow: hidden;
    border-radius: 10px;
    border: 1px solid var(--border);

    background-color: var(--bg);

    width: 100%;
    max-width: 800px;
    height: 100vh;
    max-height: 700px;
}

.player_stats .col.left {
    padding-right: 24px;
    max-width: 230px;
    padding: 12px;
    /* border-right: 1px solid var(--border); */
}
.player_stats .player {
    display: flex;
    align-items: center;
    color: var(--alt-text);
    font-weight: 600;

    padding: 7px 20px;
    margin-bottom: 3px;
    border-radius: 6px;
    border: 2px solid transparent;

    transition: background-color 0.3s ease, border-color 0.8s ease;
}
.player_stats .player:hover {
    background-color: var(--bg-transparent);
    transition: background-color 0.05s ease;
    border-color: var(--bg-transparent);
    color: var(--text);
}
.player_stats .player.active {
    color: var(--text);
    background-color: var(--accent);
    /* color: black; */
}
.avatar_head {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.player_stats .col {
    overflow-y: auto;
}
.player_stats .col.right {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow-y: unset;
}
.player_stats .col .inner {
    padding: 20px 30px;
    background-color: var(--bg-lighter);
    height: 100%;
    overflow-y: auto;

    backdrop-filter: blur(12px);
}
.player_stats .inner.individual_stats {
    padding-top: 140px;

    --img: url(https://mc-heads.net/body/MHF_Steve);
    background:
        linear-gradient(transparent 25%, var(--bg-lighter) 50%),
        var(--img),
        var(--bg-lighter);
    background-repeat: no-repeat;
    background-position: 36px 12px;
    background-size: 120px 288px;
    background-attachment: local;
}
.player_stats th {
    width: 210px;
}
.player_stats td {
    width: auto;
}
.player_stats tr[role="button"] {
    cursor: pointer;
}
.player_stats tr[role="button"]:hover {
    background-color: var(--highlight);
}
.player_stats td.compare {
    opacity: 0;
    background-color: var(--bg-lighter);
}
.player_stats tr:hover .compare {
    opacity: 1;
    background-color: var(--accent);
}
.player_stats summary {
    padding: 12px 6px;
    border-top: 1px solid var(--border);
    transition: padding 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.player_stats summary:hover {
    background-color: var(--bg-lightest);
    padding-left: 16px;
    border-radius: 5px;
}

.player_stats summary:hover,
.player_stats details:first-of-type summary,
.player_stats details:hover + details summary {
    border-color: transparent;
}

.player_stats .inner {
    transition: transform 0.5s cubic-bezier(0.1, 0.82, 0.165, 1), filter 0.5s ease, border-radius 0.7s ease;
}

#compare {
    position: absolute;
    inset: 0;

    transform: translateX(100%);
    border-radius: 30px;
}
.compare_open .inner:not(#compare) {
    filter: brightness(30%);
}
.compare_open #compare {
    transform: translateX(0);
    border-radius: 0;
}

.player_stats .compare {
    display:flex;
    justify-content: center;
    align-items: center;
}
.player_stats table img {
    vertical-align: middle;
    margin: 0 5px;
}
.player_stats .rank {
    width: 54px;
    padding-right: 8px;
    text-align: right;
    font-weight: bold;
    font-size: 14pt;
}
.player_stats .rank.gold,
.player_stats .rank.silver,
.player_stats .rank.bronze {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: 100;
}
.player_stats .rank.gold > span,
.player_stats .rank.silver > span,
.player_stats .rank.bronze > span {
    color: white;
}
.player_stats .rank.gold {
    color: rgb(255, 230, 86);
    background-color: rgb(105, 85, 32);
    font-size: 24pt;
    font-weight: bolder;
}
.player_stats .rank.silver {
    color: rgb(221, 242, 255);
    background-color: rgb(71, 93, 105);
    font-size: 20pt;
}
.player_stats .rank.bronze {
    color: rgb(224, 145, 95);
    background-color: rgb(85, 66, 54);
    font-size: 20pt;
}
.player_stats .rank > span {
    font-size: 11pt;
    color: gray;
    /* vertical-align: 5%; */
}

.auto_collapse {
    margin-left: auto;
    padding: 8px 12px;
}


/* Timeline */
#timeline {
    --container-width: 1100px;

    /* background-color: var(--bg-transparent);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px); */

    border-radius: 10px;
    /* height: 200px; */
    padding: 48px;
    padding-left: 128px;

    display: flex;
    overflow-x: auto;
}

/* Line */
#timeline .line_container {
    --color: rgba(255, 255, 255, 0.1);
    --line-stroke: 8px;
    --date-offset: 32px;

    height: 100%;
    width: var(--line-stroke);
    background-color: var(--color);
    position: relative;
}
#timeline .line_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
}
#timeline .line_container .month {
    position: absolute;
    transform: translateY(-50%);

    height: 4px;
    width: var(--line-stroke);
    border-radius: 100px;
    background-color: var(--alt-text);

}
#timeline .line_container .year {
    position: absolute;
    right: calc(100% + var(--date-offset));
    transform: translateY(-50%);

    font-size: 18pt;
    font-weight: bold;
    text-align: center;
}
#timeline .line_container .year::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(calc(var(--date-offset) + var(--line-stroke)) * -1);


    width: var(--date-offset);
    height: 5px;
    background-color: white;
    border-radius: 100px;
}

/* Item */
#timeline .timeline_item {
    position: absolute;

    background: linear-gradient(90deg, var(--bg-lighter) 20%, transparent 100%), var(--image);
    background-color: var(--bg-lighter) !important;
    background-size: cover !important;
    background-position: center !important;

    padding: 10px 18px;
    border-radius: 5px;
    border: 1px solid var(--border);

    color: var(--text);
    /* writing-mode: vertical-rl;
    text-orientation: sideways; */
}
#timeline .timeline_item h5 {
    white-space: nowrap;
    text-overflow: ellipsis;
}
#timeline .timeline_item.to_present {
    border-bottom-style:dashed;
    border-bottom-width: 2px;
    border-bottom-color: gray;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}



/* Media */
@media screen and (max-width: 680px) {
    /* Home */
    world-item {
        flex-direction: column;
    }
    world-item .col.right { margin-top: 12px; margin-left: unset; }
    world-item button { width: 100%; }

    /* Universal */
    .container {
        padding: 0 8px;
    }
    .media_flex {
        flex-direction: column;
        /* justify-content: left !important; */
    }
    .media_flex > hr.vr {
        height: 1px;
        width: 100%;
        margin-top: 16px;
    }
    .col {
        border: none !important;
        padding: unset !important;
    }
    .col:not(:first-of-type) {
        margin-top: 20px;
    }
    .media_flex > .margin_left_auto {
        margin-left: unset;
    }

    /* World */
    #world header {
        min-height: 170px;
    }
    #world h1 {
        font-size: 32pt;
        margin-right: auto;
    }
    #world .item {
        padding: 20px 24px;
    }
    img-info {
        flex-direction: column;
        text-align: center;
        padding: 20px 10px;
    }
    img-info .right {
        padding: 20px 0 0 0 ;
        text-align: center;
    }

    /* Stats */
    .player_stats .col.left {
        min-height: 200px;
        max-width: 100%;
    }
    .player_stats .col.right .inner {
        overflow-y: auto;
    }
}
